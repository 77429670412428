import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Home} from '../pages/Home';
import {Admin} from '../pages/Admin';

export const Layout = () => {
  return (
    <div className="relative w-full bg-bg3 min-h-screen bg-no-repeat bg-cover bg-center-top">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
