import React from 'react';

aaron: 'sean';
allie: 'stefanie';
bill: 'mary';
brad: 'justine';
chelsea: 'jesse';
david: 'chelsea';
janelle: 'mandy';
jesse: 'mike';
joe: 'aaron';
justine: 'joe';
kathy: 'brad';
katie: 'david';
mandy: 'katie';
mary: 'janelle';
mike: 'bill';
sean: 'tony';
stefanie: 'allie';
tony: 'kathy';

const members = [
  {
    name: 'Jessie',
    current: 'Mike',
    previous: 'Katie',
    bday: '4/25',
  },
  {
    name: 'Mary',
    current: 'Janelle',
    previous: 'Mike',
    bday: '4/25',
  },
  {
    name: 'David',
    current: 'Chelsea',
    previous: 'Brad',
    bday: '8/31',
  },
  {
    name: 'Justine',
    current: 'Joe',
    previous: 'Sean',
    bday: '2/28',
  },
  {
    name: 'Tony',
    current: 'Kathy',
    previous: 'Chelsea',
    bday: '10/1',
  },
  {
    name: 'Mandy',
    current: 'Katie',
    previous: 'Stefanie',
    bday: '11/14',
  },
  {
    name: 'Allie',
    current: 'Stefanie',
    previous: 'Kathy',
    bday: '9/16',
  },
  {
    name: 'Aaron',
    current: 'Sean',
    previous: 'Janelle',
    bday: '5/18',
  },
  {
    name: 'Katie',
    current: 'David',
    previous: 'Mandy',
    bday: '2/17',
  },
  {
    name: 'Joe',
    current: 'Aaron',
    previous: 'Mary',
    bday: '5/20',
  },
  {
    name: 'Chelsea',
    current: 'Jesse',
    previous: 'Tony',
    bday: '2/26',
  },
  {
    name: 'Janelle',
    current: 'Mandy',
    previous: 'Jessie',
    bday: '12/20',
  },
  {
    name: 'Bill',
    current: 'Mary',
    previous: 'Justine',
    bday: '2/19',
  },
  {
    name: 'Brad',
    current: 'Justine',
    previous: 'Allie',
    bday: '6/18',
  },
  {
    name: 'Stefanie',
    current: 'Allie',
    previous: 'Josh',
    bday: '7/12',
  },
  {
    name: 'Sean',
    current: 'Tony',
    previous: 'David',
    bday: '9/25',
  },
  {
    name: 'Mike',
    current: 'Bill',
    previous: 'Aaron',
    bday: '3/25',
  },
  {
    name: 'Kathy',
    current: 'Brad',
    previous: 'Joe',
    bday: '4/30',
  },
];

export const Home = () => {
  return (
    <>
      <div className="py-10">
        <h1 className="font-headline text-4xl sm:text-6xl text-center">
          Kids of <span className="text-red-500">Bob</span> & <span className="text-green-500">Nell</span>
        </h1>
        <h2 className="font-headline text-2xl sm:text-4xl mb-6 text-center">Christmas List</h2>
        <div className="px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto">
          <div className="mt-8 flex flex-col">
            <div className="lg:overflow-x-auto lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 bg-opacity-60">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-900"
                        >
                          2024
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-900"
                        >
                          2023
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-900"
                        >
                          Birthday
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white bg-opacity-60">
                      {members.map((person) => (
                        <tr key={person.name}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-bold text-gray-900 sm:pl-6">
                            {person.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">{person.current}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">{person.previous}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">{person.bday}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
