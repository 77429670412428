import React, {useState} from 'react';

export const Admin = () => {
  const [formData, setFormData] = useState({} as any);
  const [message, setMessage] = useState('');
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.firstName) {
      return;
    }

    const res = await fetch(`/api/member`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgwYTUwNWVmLTZjYzctNGU1YS1iM2MzLWM1NDg1NTdjNjE3YyIsInVzZXJuYW1lIjoic2VhbiIsImlhdCI6MTY3MTkyMjQxMn0.tRxINXUgMzlY1_YxJAuZYyBMiZPKztJfJN0lR7L1NTc',
      },
      body: JSON.stringify(formData),
    });

    const json = await res.json();

    if (json.ok) {
      setMessage('Member added.');
    } else {
      setMessage('Something went wrong!');
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="mt-8 space-y-6" action="#" method="POST">
        <div className="rounded-md">
          <div className="mb-6">
            <label htmlFor="firstName" className="block text-md font-medium text-gray-700">
              First Name
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="block w-full rounded-md border-gray-300 pr-10 text-gray-900 placeholder-gray-300 focus:border-primary focus:outline-none focus:ring-primary-50 sm:text-md"
                placeholder="Sean"
                onChange={handleOnChange}
              />
            </div>
          </div>
          <p className={complete ? 'mt-2 text-sm text-primary' : 'mt-2 text-sm text-red-600'} id="name-error">
            {message}
          </p>
          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
